@import url('https://fonts.googleapis.com/css?family=Raleway:400,500');

$primary: #e23b96; /* MAIN COLOR */
$secondary: #4196c3; /* SECONDARY COLOR */
$third: #9c3f97; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #fafafa;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 1.5em;
  font-family: 'Raleway', sans-serif;
	h1,h2,h3,h4,h5,h6 {
		font-size:2.4em;
	}
}

nav {
	z-index: 10;
  background: $primary;

}
.navbar  {
    background-color: $primary;
    border: 0;
}

.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
> li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;
      color: $wht;

			@media (max-width: 767px) {
			  	margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
          border: 1px solid $wht;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $wht;
            border:none;

				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 1em 1.5em;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin: 2em 0em;
  text-decoration: none;

	&:hover {
    background: transparent;
  	color: $primary;
		text-decoration: none;

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
section {
  padding: 150px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media(max-width: 1024px){
    padding: 130px 0;
  }
  @media(max-width: 991px){
    padding: 120px 0;
  }
  @media(max-width: 767px){
    padding: 100px 0;
  }
  @media(max-width: 600px){
    padding: 80px 0;
  }
  @media(max-width: 500px){
    padding: 60px 0;
  }
}
.section-a {
  background-image: url('../img/bg1.jpg');
   h1 {
     font-size: 2.5em;
     text-transform: uppercase;
     font-weight: bold;
     @media(max-width: 767px){
       font-size: 1.5em;
     }
     @media(max-width: 767px){
       font-size: 1.4em;
     }
     @media(max-width: 767px){
       font-size: 1.2em;
     }
   }
  p{
    margin-bottom: 50px;
    font-size: 1.5em;
    @media(max-width: 767px){
      font-size: .8em;
    }
  }

}
.section-b {
  background-color: $secondary;
  color: $wht;
  padding:80px 0;
  @media(max-width: 767px){
    padding: 60px 0;
  }

  h1 {
    text-transform: uppercase;
    margin-bottom: 40px;
    @media(max-width: 767px){
      font-size: 1.8em;
    }
  }
  p{
    line-height: 1.8em;
    margin-bottom: 35px;
    @media(max-width: 767px){
      font-size: .9em;
      line-height: 1.6em;
    }
  }
  img{
    margin-bottom: 35px;
    box-shadow: 0px 0px 10px darken($secondary, 25%);
    // border: 1px solid $wht;
    // padding: 25px ;

  }

}
.section-c {
  background-color: lighten($wht, 5%);
  color: $blk;
  padding:80px 0;
  @media(max-width: 767px){
    padding: 60px 0;
  }
  h1 {
    @media(max-width: 767px){
      font-size: 1.8em;
    }
  }
  h2{
    font-size: 1.5em;
    text-transform: uppercase;
    margin-bottom: 20px;
    @media(max-width: 767px){
      font-size: 1.8em;
    }
  }
  p{
    font-size: 1.2em;
    line-height: 1.7em;
    font-style: italic;
    margin-bottom: 35px;

  }
  img{
    margin-bottom: 25px;
    border: 1px solid $wht;
    padding: 10px ;
  }

}
.section-d {
  background-color: darken($third, 5%);
  color: $wht;
  padding:80px 0;
  @media(max-width: 767px){
    padding: 60px 0;
  }

    h1 {
      text-transform: uppercase;
      margin-bottom: 40px;
      @media(max-width: 767px){
        font-size: 1.8em;
      }
    }
  h4 {
    font-size: 1.5em;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p{
    font-size: .9em;
    line-height: 1.7em;
    margin-bottom: 35px;

  }
  img{
    margin-bottom: 25px;
    border: 1px solid $wht;
    padding: 10px ;
  }

}
.section-e{
  .contatc-info {
    border-right: 1px solid $blk;
    @media(max-width: 991px){
      border-right: none;
      padding-bottom: 45px;
      border-bottom: 1px solid $blk;

    }
  }
  .info {
    margin-top: 15%;
    @media(max-width: 991px){
      margin-top: 5%;
    }
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
    @media(max-width: 767px){
      font-size: 1.8em;
    }
  }
  i {
    margin-right:15px;
  }
  a {
    text-decoration: none;
    color: $blk;
  }
}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;

	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}
